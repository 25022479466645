import { Dispatch, FC, SetStateAction, useMemo } from 'react';
import classes from './ActivityScheduleSummary.module.scss';
import { Drawer, useMantineTheme, Box } from '@mantine/core';

import { useQuery } from '@apollo/client';
import { useMediaQuery } from '@mantine/hooks';
import { showErrorMessage } from 'utils/showErrorMessage/showErrorMessage';
import { LoadingCards } from 'components/LoadingCards';
import { ActivityType } from 'interfaces';
import { ClassesDateFilterEnum } from 'enums';
import { DeleteModal } from '../DeleteModal';
import { getSingleActivity } from '../../graphql/singleActivityQuery';
import ActivityScheduleSummary from './ActivityScheduleSummary';

interface IActivityScheduleSummaryDrawerProps {
  openedActivityScheduleSummary: boolean;
  close(): void;
  selectedActivityId?: string;
  token: string;
  supplierId: string;
  attendanceOverviewId?: string;
  onClone(clonedActivityId: string | undefined): void;
  onInviteAttendee(mobileSelectedActivityId: string | undefined): void;
  setActivityToDelete: Dispatch<SetStateAction<string | null>>;
  activityToDelete: string | null;
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>;
  showDeleteModal: boolean;
  onDelete(): void;
  setClassesDateFilter: (val: ClassesDateFilterEnum) => void;
  classesDateFilter: ClassesDateFilterEnum;
}

const ActivityScheduleSummaryDrawer: FC<IActivityScheduleSummaryDrawerProps> = ({
  openedActivityScheduleSummary,
  close,
  selectedActivityId,
  token,
  supplierId,
  attendanceOverviewId = '',
  onClone,
  onInviteAttendee,
  showDeleteModal,
  setShowDeleteModal,
  activityToDelete,
  setActivityToDelete,
  onDelete,
  setClassesDateFilter,
  classesDateFilter,
}) => {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, true);
  const { data, loading } = useQuery(getSingleActivity, {
    notifyOnNetworkStatusChange: true,
    skip: !selectedActivityId || !token,
    fetchPolicy: 'network-only',
    variables: {
      supplierId: supplierId,
      activityId: selectedActivityId,
    },
    context: {
      headers: {
        Authorization: token,
      },
    },
    onError: (error) => {
      showErrorMessage(error);
    },
  });

  const selectedActivity = useMemo<ActivityType | null>(() => {
    return data?.activitiesBySupplier?.activities?.[0];
  }, [data]);

  return (
    <Drawer
      withCloseButton={false}
      opened={openedActivityScheduleSummary}
      onClose={close}
      classNames={{
        content: classes.drawerContent,
        header: classes.drawerHeader,
        body: classes.drawerBody,
      }}
      position={isMobile ? 'bottom' : 'right'}
      overlayProps={{ backgroundOpacity: isMobile ? 0.5 : 0 }}
      title={selectedActivity?.name}
    >
      {loading || !selectedActivity ? (
        <Box mt="xl">
          <LoadingCards />
        </Box>
      ) : (
        <ActivityScheduleSummary
          close={close}
          selectedActivity={selectedActivity}
          token={token}
          supplierId={supplierId}
          onClone={onClone}
          onInviteAttendee={onInviteAttendee}
          setActivityToDelete={setActivityToDelete}
          activityToDelete={activityToDelete}
          setShowDeleteModal={setShowDeleteModal}
          setClassesDateFilter={setClassesDateFilter}
          classesDateFilter={classesDateFilter}
          attendanceOverviewId={attendanceOverviewId}
        />
      )}
      <DeleteModal
        afterDelete={() => {}}
        token={token}
        open={showDeleteModal && !!activityToDelete}
        activityId={activityToDelete}
        onClose={() => {
          setActivityToDelete(null);
          setShowDeleteModal(false);
          onDelete();
          close();
        }}
        onCancel={() => {
          setActivityToDelete(null);
          setShowDeleteModal(false);
        }}
      />
    </Drawer>
  );
};

export default ActivityScheduleSummaryDrawer;
